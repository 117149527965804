import React, { useState, useEffect } from "react";
import axios from "axios";
import submissionVideo from "./Assets/Submission.mp4";

export default function ContactForm({lang}){
    const formRef = React.useRef(null);
    const videoRef = React.useRef(null);
    const successRef = React.useRef(null);
    const [listener, setListener] = useState(false);

    const initialState = {
        name: "",
        email: "",
        subject: "",
        message: "",
        origin: "Eightyeightdays.com - Coding Portfolio",
        originUrl: "www.code.eightyeightdays.com",
        carbonCopy: false,
        lang: ""
    };

    const [form, setForm] = useState(initialState);

    function resetForm(){
        setForm({...initialState});
        successRef.current.classList.add("hidden");
    };

    function submitEmail(e){
        e.preventDefault();
        form.lang = lang; // Use the latest language settings
        axios({
            method: "POST",
            // url: "http://localhost:4000/send",
            url: "https://nodemailer-contact-form-api.vercel.app/send",
            data: form
        })
        .then(response =>{
            // console.log(response)
            if(response.status === 200){
                setLoop(false);
                resetForm();
            }else if(response.status !== 200){
                alert("Sending failed!!!");
                console.log(response.error)
            }
        });
    }

    function handleVideoLoop(){
        if(listener){return};
        setListener(true)
        videoRef.current.play(); 
    };

    const loopVideo = (e)=>{
        if(e.target.currentTime >= 7){
            videoRef.current.currentTime = "0";
        };
    };

    const showSuccessMessage = (e)=>{
        if(e.target.currentTime >= 9.5){
            successRef.current.classList.remove("hidden");
        };
    };

    const [loop, setLoop] = useState(true);

    useEffect(()=>{
        // console.log("EFFECT RAN")
        const videoElement = videoRef.current;
      
        if(loop){
            videoElement.addEventListener("timeupdate", loopVideo);
            videoElement.addEventListener("timeupdate", showSuccessMessage);
            // console.log("EVENT LISTENERS ADDED");
        };
      
        return () =>{
            videoElement.removeEventListener("timeupdate", loopVideo);
            // console.log("LOOP REMOVED");
        };

    }, [loop]);
    
    return(
    <div className="contact-container">
        <div className="contact-container-inner">
            <form ref={formRef} className="contact-form" id="contact-form" onSubmit={submitEmail} method="POST" onClick={handleVideoLoop}>
                <div className="senderName">
                    {lang == "english"?
                    <input type="text" name="sender" placeholder="Name" required onChange={(e)=>setForm(prevState => ({...prevState, name: e.target.value}))} value={form.name}></input>
                    :
                    <input type="text" name="sender" placeholder="Nom" required onChange={(e)=>setForm(prevState => ({...prevState, name: e.target.value}))} value={form.name}></input>
                    }
                </div>
                <div className="sender-address">
                    <input type="email" name="senderAddress" placeholder="Email" required onChange={(e)=>setForm(prevState=>({...prevState, email: e.target.value}))} value={form.email}></input>
                </div>
                <div className="subject">
                    {lang === "english"? 
                        <input type="text" name="subject" placeholder="Subject" required onChange={(e)=>setForm(prevState=>({...prevState, subject: e.target.value}))} value={form.subject}></input>
                        :
                        <input type="text" name="subject" placeholder="Objet" required onChange={(e)=>setForm(prevState=>({...prevState, subject: e.target.value}))} value={form.subject}></input>
                    }
                </div>
                <div className="message">
                    <textarea type="text" name="message" placeholder="Message" required onChange={(e)=>setForm(prevState => ({...prevState, message: e.target.value}))} value={form.message}></textarea>
                </div>
                <div className="carbonCopy">
                    <label className="ccLabel" htmlFor="carbonCopy">
                        {lang === "english"? "Receive a copy of this email" : "Envoyez-moi une copie"}
                    </label>
                    <input id="checkBox" type="checkbox" checked={form.carbonCopy} name="carbonCopy" value={form.carbonCopy} onChange={(e)=>setForm(prevState => ({...prevState, carbonCopy: e.target.checked}))}></input>
                </div>
            </form>
            <div className="video-container">
                <video ref={videoRef} id="video" className="contact-video" src={submissionVideo} muted ></video>
                <div className={`success-message-box `} >
                    <div className={`success-message hidden`} ref={successRef}>
                        {lang === "english"? "MESSAGE SENT!" : "ENVOYÉ!"}
                    </div>
                </div>
            </div>
        </div>
        <button className="submitButton" type="submit" form="contact-form">
            {lang === "english" ? "SUBMIT!!!" : "SOUMETTRE!!!"}
        </button>
        {/* <div className="test-button" onClick={()=> {setLoop(false)}}>TEST</div> */}
    </div>
    )
}