import './App.css';
import { useState } from 'react';
import {Data as data} from "./Data.js"
import ContainerItem from './ContainerItem';
import video from "./Assets/As Seen Through Windows.mp4";
import poster from "./Assets/ASTW.jpg";
import portrait2 from "./Assets/portrait2.jpg";
import mario from "./Assets/mario.png";
import { paperclip, fullStar, emptyStar } from './Data.js';
import ContactForm from './ContactForm';

export default function App() {
  const [content, setContent] = useState(null);
  const [lang, setLang] = useState("english");

    function showContent(type){
      if(content === type){return}; // Prevent unnecesary animation

      const root = document.querySelector(":root");
      root.style.setProperty("--initial", "-900px");

      if(content == null){
        setContent(type);
      }else{
         setTimeout(()=>{
          setContent(type);
        }, "300");
      }

      setTimeout(()=>{ 
        root.style.setProperty("--initial", 0);
      }, "400");
    }

  return (
    <div className='app'>
      <div className='buttons'>
        {lang == "english"? 
          <div className='lang-button' onClick={()=>setLang("french")}>🇫🇷 Fran🥐ais</div> 
          : 
          <div className='lang-button' onClick={()=>setLang("english")}>🇬🇧 English</div>
        }
      </div>
      <div className='container'>
        <div className='blur'></div>
        <div className='nav'>
          {data[lang].navTitles.map((item, index)=>{
            return(
              <div className='nav-title' key={index} onClick={()=>{showContent(item)}}>{item}</div>
            )
          })}
        </div>
        <div className='view'>
          {content == "projects" || content == "travaux" ?
            data[lang].projects.map((item, index)=>{
              return(
                <ContainerItem lang={lang} key={index} title={item.title} summary={item.summary} description={item.description} tools={item.tools} link={item.link} linkText={item.linkText} gitLink={item.gitLink} src={item.src} />
              )
            })
          :content == "about" || content == "à propos"?
            <div className={`view-item about`}>
              {/* <div className='about-text'>{data[lang].about}</div> */}
              <div className='about-text'>
                {data[lang].skills.map((item, index)=>{
                  if(lang == "english" && index == data[lang].skills.length-1){
                    return(
                    <div className='skill' key={index}> 
                      <div className='skill-text'>{item.text}: </div>
                      <div className='skill-rating'>
                        {[...Array(item.rating)].map((clip, indexp)=>{
                        return(
                          paperclip(indexp)
                        )
                        })}
                      </div>
                    </div>
                    )
                  };
                  if(index == data[lang].skills.length-1){
                    return(
                      <div className='skill' key={index}> 
                        <div className='skill-text'>{item.text} : </div>
                        <div className='skill-rating'>
                          {[...Array(item.rating)].map((star, i)=>{
                            return(
                              fullStar(i)
                            )
                          })}
                        </div>
                      </div>
                    )
                  };
                  return(
                    <div className='skill' key={index}> 
                      <div className='skill-text'>{item.text} : </div>
                      <div className='skill-rating'>
                        {[...Array(item.rating)].map((star, index2)=>{
                          return(
                            fullStar(index2)
                          )
                        })}
                        {[...Array(10 - item.rating)].map((star2, index3)=>{
                          return(
                            emptyStar(index3)
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
                {lang == "french" && <img className='mario' src={mario}></img>}
              </div>
              <img className='about-img' src={portrait2}></img>
            </div>
          :content == "contact"?
            <div className='view-item'>
              <ContactForm lang={lang} />
            </div>
          :content == null? <video autoPlay muted loop playsInline src={video} poster={poster} className='video'></video>
          : null}
        </div>
      </div>
      
    </div>
  );
}