export const Data = {
    french: {
        navTitles: [
            "travaux", "à propos", "contact"
        ],
        projects: [
            {
                title: "Eightyeightdays.com",
                summary: "Un portfolio de mes travaux artistiques des 10 dernières années.",
                description: `Le domaine primaire est utilisé pour le front-end qui à été créé avec Next.js et hébergé par un fournisseur cPanel.<br>
                admin.eightyightdays.com est utilisé pour héberger WordPress qui fonctionne en tant que CMS sans tête.<br>
                Le sous-domaine sur lequel vous vous retrouvez actuellement est utilisé pour une application React qui est hébergé par Vercel.<br>
                <a href="https://github.com/Eightyeightdays/eightyeightdays.com/tree/main/front">La branche principale sur GitHub</a> à été utilisé pour intégrer le le CMS Strapi avec le front-end, mais n'a pas pu être déployé au cause des limitations de l'hébergeur.`,
                tools: "WordPress, Strapi, Next.js",
                link: "https://www.eightyeightdays.com",
                linkText: "Eightyeightdays.com",
                gitLink: "https://github.com/Eightyeightdays/eightyeightdays.com",
            },
            {
                title: "YouTube Playlist Creator",
                summary: "Une extension pour Firefox qui permet aux utilisateurs à créer des playlists sur YouTube sans besoin d'avoir un compte.",
                description: `Les utilisateurs de cette extension peuvent glisser-déposer les vidéos pour changer l'ordre des titres, utiliser un menu contextuel pour ajouter des vidéos ou les rajouter par le menu principale, trier tous les playlists par ordre alphabétique, par age ou par date de création.<br>
                L'extension est gratuite et disponible <a href="https://addons.mozilla.org/en-GB/firefox/addon/youtube-playlist-creator">sur ce lien</a>.`,
                tools: "HTML, CSS JavaScript",
                link: "https://addons.mozilla.org/en-GB/firefox/addon/youtube-playlist-creator/",
                linkText: "Téléchargez ici",
                gitLink: "https://github.com/Eightyeightdays/lister",
            },
            {
                title: "Créateur et Traceur de CV",
                summary: "Une application full-stack pour créer des CV personnalisées et pour tracer les origines des clics sur les liens internes.",
                description: `En utilisant un modèle que j'ai créé avec React, je peux modifier et basculer les informations sur mon CV avant d'enregistrer le document en format PDF directement dans le navigateur.<br>
                Un numéro de suivi unique est automatiquement attribué à chaque CV qui me permet d'identifier quelle entreprise à cliqué sur quel lien. Je reçois un mail qui détaille les informations sur le poste et les statistiques de tous ces clics sont enregistrés et affichés sur le front-end.<br>
                <a href="https://github.com/Eightyeightdays/click-logger">Le back-end</a> est une application Node.js qui est intégrée avec MongoDB et hébergée sur Vercel.`,
                tools: "React, Node, Express, Mongoose et MongoDB Atlas.",
                link: "https://click-stats.vercel.app/",
                linkText: "Click Logger",
                gitLink: "https://github.com/Eightyeightdays/click-stats",
            }
        ], 
        about: ``,
        skills: [
            {text: "HTML", rating: 4},
            {text: "CSS", rating: 5},
            {text: "JavaScript", rating: 6},
            {text: "Centrer un div", rating: 3},
            {text: "Trouver des réponses sur Stack Overflow", rating: 8},
            {text: "Passer trop de temps à mater YouTube", rating: 9},
            {text: "Attention aux détals", rating: 7},
            {text: "Ironie", rating: 9},
            {text: "Capacité à empêcher les étoiles d'apparaître correctement sur la même ligne", rating: 10},
            {text: "Super Mario", rating: 1},
        ]
    },
    english: {
        navTitles: [
            "projects", "about", "contact"
        ],
        projects: [
            {
                title: "Eightyeightdays.com",
                summary: `A showcase of some of my artwork from the last 10 years.`,
                description: `The main domain is used to serve a front-end built using Next.js with SSG, and is hosted by a cPanel provider.<br>
                admin.eightyightdays.com is used to host a WordPress installation which functions as a headless CMS.<br> 
                This subdomain that you are currently on is used to serve a React application hosted on Vercel.<br>
                <a href="https://github.com/Eightyeightdays/eightyeightdays.com/tree/main/front">The main Git branch</a> was used to supply content to the front-end with Strapi, but which couldn't be deployed on basic shared hosting due to RAM limitations.
                `,
                tools: "WordPress, Strapi, Next.js",
                link: "https://www.eightyeightdays.com",
                linkText: "Eightyeightdays.com",
                gitLink: "https://github.com/Eightyeightdays/eightyeightdays.com",
            },
            {
                title: "YouTube Playlist Creator",
                summary: `An extension for the Firefox browser which allows users to create and share playlists without needing a YouTube account.`,
                description: `Users can drag and drop videos to change the order of titles, use a right-click menu in order to add videos or add the current page via the extension menu, and sort all playlists alphabetically, by age or by date edited.<br>
                The extension is free to download via the Mozilla Add-ons store.`,
                tools: "HTML, CSS JavaScript",
                link: "https://addons.mozilla.org/en-GB/firefox/addon/youtube-playlist-creator/",
                linkText: "Download Here",
                gitLink: "https://github.com/Eightyeightdays/lister",
            },
            {
                title: "CV Generator & Tracker",
                summary: `A full-stack application for easily creating custom CVs and tracking the clicks that the links receive.`,
                description: `
                Using a template built in React I can easily edit and toggle information on my CV, such as language, before saving directly in the browser as a PDF.<br> 
                Not only that, but each CV is automatically assigned a unique identifier which is used to track and log the different clicks on the links. When A link is clicked I receive an email with details about the job and employer, while the statistics are recorded and rendered on the front-end.<br>
                <a href="https://github.com/Eightyeightdays/click-logger">The back end</a> is a Node.js application integrated with MongoDB and hosted on Vercel.`,
                tools: "React, Node, Express, Mongoose and MongoDB Atlas.",
                link: "https://click-stats.vercel.app/",
                linkText: "Click Logger",
                gitLink: "https://github.com/Eightyeightdays/click-stats",
            }
        ],
        about: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
        skills: [
            {text: "HTML", rating: 4},
            {text: "CSS", rating: 5},
            {text: "JavaScript", rating: 6},
            {text: "Centering a div", rating: 3},
            {text: "Finding answers on Stack Overflow", rating: 8},
            {text: "Unnecessary use of animated gifs in Medium articles", rating: 9},
            {text: "Attention to detal", rating: 7},
            {text: "Irony", rating: 9},
            {text: "Ability to assign arbitrary value judgments to intangible qualities", rating: 10},
            {text: "Imminent A.I apocalypse", rating: 35},
        ]
    }
};

export const paperclip = (index) => (<svg className="paperclip" key={index} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z"/></svg>);

export const fullStar = (index) => (
  <svg className="star" key={index} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg>
);

export const emptyStar = (index) => (
  <svg className="star" key={index} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"/></svg>
);